<template>
  <store-vat-sales-report />
</template>

<script>
import StoreVatSalesReport from '@/components/pages/sales/StoreVatSalesReport'

export default {
  components: {
    StoreVatSalesReport
  }
}
</script>
